@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
html, body{
    margin:0;
    padding:0;
    height:0;
    font-family:Roboto;
    background-color:#121212;
}

.verticalcontainer{
    width:16%;
    background-color:black;
    height:85%!important;
    position:absolute;
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    color:#cccccc;
    padding:1rem 0;
    align-self:center;
}

.verticalcontainer a{
    color:#cccccc;
    text-decoration: none;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
    padding-left:1em;
    line-height:40px;
    font-size:0.75em;
    border-left:4px solid transparent;
    display:flex;
    align-items:center;
    font-weight:700;
}

.verticalcontainer svg{
    font-size:20px;
    margin-right:10px;
}

.verticalcontainer a:hover{
    color:white;
    border-left:4px solid #1ED760;
}

.playlistsdiv h6{
    padding-left:19px;
    margin-top:20px;
    margin-bottom:10px;
    font-weight:200;
}

.playlistsbox{
    margin-top:10px;
    height:165px;
    width:99%;
    overflow-y:scroll;
}
.playlistsbox ul{
    list-style: none;
    padding-left:19px;
    margin-top:0;
}

/* Scrollbar styles */
::-webkit-scrollbar {
    width: 17px;
    }
    
::-webkit-scrollbar-thumb {
    background: #505050;  
    }
::-webkit-scrollbar-thumb:hover {
    background:#505050;  
    }

.playlistsbox  li a{
        margin:0!important;
        padding:0!important;
        border:none!important;
        font-weight:normal;
        line-height:30px;
}

.playlistsdiv a:hover{
    border-left:4px solid transparent
}

.plus svg{
    color:black;
    background-color:#9A9A9A;
    padding:6px;
}
.footerplayercontainer{
    position:absolute;
    height:15%;
    bottom:0;
    background-color:#282828;
    width:100%;
    display:flex;
    justify-content: center;
    align-items:center;
}

.row{
    display:inline-flex;
    align-items:center;
}
.footerplayercontainer .row button{
    background-color:transparent;
    color: #B3B3B3;
    border:0;
    font-size:13px;
    padding-right:15px;
    padding-left:15px;
    outline:0;

}
.footerplayercontainer .row .play{
    font-size:20px;
}
.cardcontainer{
    height:270px;
    width:200px;
    background-color:#282828;
    border-radius:10px;
    margin-right:15px;
}

.cardcontent{
    padding:20px;
    line-height:5px;
}
.cardcontent h4{
    color:white;
    padding-top:10px;
}
.cardcontent p{
    font-size:13px;
    color: #A4A4A4;
}

.cardcontent img{
    height:150px;
    width:100%;
}
.pagecontainer{
position:absolute;
left:16%;
padding: 30px 25px;
}

.tabsbar a{
    padding-right:30px;
    font-size:13px;
    font-weight:700;
    text-decoration:none;
    color:#999999;
    -webkit-transition:color 0.3s ease-in-out;
    transition:color 0.3s ease-in-out;
}

.tabsbar a:hover{
    color:white;
}

h1{color:white;
margin-top:60px;
line-height:10px;}

p{
    color:#A4A4A4;
    font-size:15px;
}
.searchcardcontainer{
    display:flex;
    height:180px;
    width:170px;
    background-color:orange;
    border-radius:10px;
    margin-right:20px;
    justify-content: center;
    align-items: flex-start;
}

#searchinpdiv{
    border-radius:200px;
    height:40px;
    border:none;
    outline:0;
    width:60%;
    background-color:white;
    display:flex;
    align-items:center;
    padding:0 10px;
}
#searchinp{
    width:100%;
    height:80%;
    border:none;
    line-height:40px;
    font-size:0.8em;
    outline:0;
    padding-left:10px;
}
body{
    overflow-x:hidden;
}

.collectioncardcontainer{
    width:145px;
    height:145px;
    display:flex;
    margin-right:20px;
}

.collectioncardcontainer img{
    width:100%;
    height:100%;
}
.margindown{
    margin-bottom:50px;
}
